.start-subscription-btn-card-logo {
  border-radius: 4px;
  width: 38px;
  height: 24px;
}

@media only screen and (max-width: 600px) {
  .start-subscription-btn-card-logo {
    width: 24px;
    height: 16px;
  }
}
